import React from "react";
import "./welcome.css";
const Welcome = () => {
  return (
    <div className="welcome-contianer">
      <div className="welcome-head">
        <h2>WELCOME TO IGNTI</h2>
      </div>
      <div className="welcome-section">
        <p className="p">
          Indira Gandhi National Training Institute was founded in 2017
          with the mission of providing best quality Computer education and
          Spoken & Basic English to all classes of people at a very reasonable fee
          structure.Thousands of students have already got trained professionally
          and made their career successfully in the past. Over the past few years,
          the growth of the computer industry and English language has been quite
          remarkable and today it is the fastest growing industry.

        </p>

        <p className="p">
          Our organization not only provides the platform to build up a
          bright professional career in the computer field and linguistics but also
          provides the placement opportunities in reputed companies. As
          Computer and English knowledge has become primary requirement for
          everyone, our Institute provides the best Quality Computer Education
          and Spoken & Basic English in most reasonable fee structure to all
          classes of people. Information Technology has become a fastest growing
          Industry in today's scenario which is providing thousands of job to well
          trained professionals. India has achieved a terrific growth in this field
          and is being considered as a Super Power in the world. Information
          Technology is providing the upcoming future that will change every face
          of Human existence.


        </p>

        <p className="p">
          In order to produce Information Technology Professionals, IT
          education requires a good infrastructure and high quality competence.
          Our Mission has been decided to provide the Computer Education by the
          help of State & Indian Central Government planning & Programmes
          announces time to time in nominal charge for every person of our lower
          and middle class of society. I wish and try to success the dream of
          computer education in all India.
        </p>



      </div>

      {/* <div className="read-more">
        <a className="read" href="#">
          Read More....
        </a>
      </div> */}
    </div>
  );
};

export default Welcome;
