import React from "react";
import "./igt.css";
import Tabs from "../tabs/Tabs";
import Welcome from "../welcome/Welcome";

const Igt = () => {
  return (
    <div className="iginit-container">
      <Tabs  />
      <Welcome />
    </div>
  );
};

export default Igt;
